import { Fragment, useEffect, useState } from 'react'
import { useLoader } from '~/components/shared/CustomHooks/useLoader'
import isEmpty from 'lodash/isEmpty'
import {
  SHOPPING_LIST_ROUTE,
  SUBMITTED_ORDERS_ROUTE,
} from '~/components/shared/EwayRouter/EwayRouterConstants'
import EwayRouter from '~/components/shared/EwayRouter'
import { dynamicTranslation } from '~/components/shared/DynamicTranslation/DynamicTranslationUtils'
import EwayLink from '~/components/shared/EwayLink'
import Price from '~/components/shared/Price'
import Text from '~/components/shared/Text'
import messages from './messages'
import { fetchAllowance } from '~/api/allowance/allowanceService'
/**
 *
 * @param {number} total
 * @param {string} language
 * @returns
 */
export function formatAllowanceMessages(total, language) {
  const translations = messages[language]
  const shoppingListLink = EwayRouter.getUrl(SHOPPING_LIST_ROUTE)
  const submittedOrdersLink = EwayRouter.getUrl(SUBMITTED_ORDERS_ROUTE)
  const paragraphOne = dynamicTranslation(translations.longMessage1, {
    '{total}': (
      <Fragment key={0}>
        {/* Setting the variant="" to make text inherit fontSize */}
        <Text fontFamily="md" fontWeight="strong" color="secondary" variant="">
          <Price language={language} data-test="allowance-dashboard-total">
            {total}
          </Price>{' '}
        </Text>
      </Fragment>
    ),
  })
  const paragraphTwo = dynamicTranslation(translations.longMessage2, {
    '{shoppingListLink}': (
      <Fragment key={1}>
        <EwayLink
          href={shoppingListLink}
          data-test="allowance-dashboard-shopping-list-link"
          text={translations.shoppingList}
          aria-label={translations.shoppingList}
          color="primary"
        />
      </Fragment>
    ),
    '{NOTE}': (
      <Fragment key={2}>
        <Text fontFamily="md" fontWeight="strong" color="secondary" variant="">
          {translations.shortMessage}
        </Text>
      </Fragment>
    ),
  })
  const paragraphThree = dynamicTranslation(translations.longMessage3, {
    '{submittedOrdersLink}': (
      <Fragment key={1}>
        <EwayLink
          href={submittedOrdersLink}
          data-test="allowance-dashboard-submitted-orders-link"
          text={translations.submittedOrders}
          aria-label={translations.submittedOrders}
          color="primary"
        />
      </Fragment>
    ),
  })
  return { paragraphOne, paragraphTwo, paragraphThree }
}

export function useAllowance({ canFetchAllowance, email, clientNo, language }) {
  const [allowance, setAllowance] = useState({})
  const allowanceEmpty = isEmpty(allowance)
  const { setPending, setError, setResolved, isLoading } = useLoader()
  useEffect(() => {
    async function startFetchAllowance() {
      setPending()
      const {
        isSuccess,
        allowanceRemainingAmount: remainingAmount,
        allowanceTotal: total,
      } = await fetchAllowance(email, clientNo)
      setResolved()
      if (!isSuccess) {
        setError()
        return
      }
      setAllowance({
        exists: !!isSuccess,
        remainingAmount,
        total,
      })
    }

    canFetchAllowance && allowanceEmpty && startFetchAllowance()
  }, [
    language,
    canFetchAllowance,
    allowanceEmpty,
    clientNo,
    email,
    setError,
    setPending,
    setResolved,
  ])
  return {
    isLoading,
    ...allowance,
  }
}
