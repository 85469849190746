import { SUCCESS_FALSE } from '../apiHelperConstants'
import { get } from './allowanceOperations'
// To quickly debug the service
const DEBUG = false
export const fetchAllowance = async (...args) => {
  const [email, clientNo] = args
  if (!email || !clientNo) {
    return SUCCESS_FALSE
  }
  // To quickly debug the service
  if (DEBUG) {
    return MOCK_VALUE
  }

  try {
    const result = await get(args)
    const { balance, total, isSuccess } = result
    const emptyResult = Number(balance) === 0 && Number(total) === 0
    if (emptyResult || !isSuccess) {
      return SUCCESS_FALSE
    }
    return {
      ...result,
      isSuccess: true,
      allowanceRemainingAmount: balance,
      allowanceTotal: total,
    }
  } catch (error) {
    return {
      isSuccess: false,
      error,
    }
  }
}

const MOCK_VALUE = {
  isSuccess: true,
  allowanceRemainingAmount: 10,
  allowanceTotal: 20,
}
