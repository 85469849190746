export default {
  en: {
    longMessage1: `Your organization has granted an allowance of {total} (inclusive of taxes), available on your eway account. The value of the purchases made through your eway account will be deducted from the available balance. You are required to enter your personal credit card during checkout in order to use this eway account, regardless of the total value of your purchase. Any purchases that exceed your allocated Expense Allowance will be billed to your credit card. If your purchase does not exceed your allocated expense allowance (tax included) your credit card will not be charged.`,
    longMessage2: `Your organization has negotiated preferred pricing on your behalf for a complete home office solution, these items can be found on your {shoppingListLink} page. {NOTE}`,
    longMessage3: `The remaining balance of your Expense Allowance reflects purchases that have been invoiced, but does not include pending orders that have not yet been invoiced. It is your responsibility to keep track of the total value of submitted orders and any unprocessed invoices. Open orders can be viewed on the {submittedOrdersLink} page.`,
    shortMessage: ` Note: only orders placed on eway are eligible for this program.`,
    shoppingList: 'Shopping List',
    submittedOrders: 'Submitted Orders',
  },
  fr: {
    longMessage1: `Votre organisation a accordé une allocation de {total} (taxes comprises), disponible sur votre compte eway. La valeur des achats effectués via votre compte eway sera déduite du solde disponible. Vous devez entrer votre carte de crédit personnelle en passant à la caisse afin d'utiliser ce compte eway, quelle que soit la valeur totale de votre achat. Tous les achats qui dépassent votre allocation de dépenses allouée seront facturés sur votre carte de crédit. Si votre achat ne dépasse pas votre allocation de dépenses (taxes incluses), votre carte de crédit ne sera pas débitée.`,
    longMessage2: `Votre organisation a négocié des tarifs préférentiels en votre nom pour une solution complète de bureau à domicile, ces articles se trouvent sur votre page {shoppingListLink}. {NOTE}`,
    longMessage3: `Le solde restant de votre allocation de dépenses reflète les achats qui ont été facturés, mais n'inclut pas les commandes en attente qui n'ont pas encore été facturées. Il est de votre responsabilité de garder une trace de la valeur totale des commandes soumises et de toutes les factures non traitées. Les commandes ouvertes peuvent être consultées sur la page des {submittedOrdersLink}.`,
    shortMessage: ` Note : seules les commandes effectuées sur eway sont admissibles à ce programme.`,
    shoppingList: `Liste d'achat`,
    submittedOrders: `Commandes soumises`,
  },
}
