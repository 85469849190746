import PropTypes from 'prop-types'
import { handleTooltipShowHide } from '~/services/utils'
import Box from '../Box'
import IconInfo from '../IconInfo'
import Text from '../Text'
import Tooltip from '../Tooltip'
import styles from './InfoTooltip.module.scss'

function InfoTooltip({
  children,
  text,
  id,
  offset,
  place,
  as = 'span',
  stayOpenOnHover = false,
  ...rest
}) {
  return (
    <Box as={as} {...rest}>
      <IconInfo
        className={styles.icon}
        data-tip
        tabIndex="0"
        onFocus={handleTooltipShowHide}
        onBlur={handleTooltipShowHide}
        data-for={id}
        aria-describedby={id}
        aria-label={text}
      />
      {children}
      <Tooltip
        id={id}
        place={place}
        effect="solid"
        stayOpenOnHover={stayOpenOnHover}
        className={styles.tooltip}
        offset={offset}
      >
        <Text text={text} as="div" />
      </Tooltip>
    </Box>
  )
}

InfoTooltip.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
  text: PropTypes.node,
  place: PropTypes.string,
  offset: PropTypes.object,
  stayOpenOnHover: PropTypes.bool,
  as: PropTypes.string,
}

export default InfoTooltip
