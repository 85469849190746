import { getHostUrl } from 'services/utils/configUtils'
import { CONTENT_TYPE_JSON, sendRequest } from '../apiHelper'
const HOST_URL = getHostUrl()

export const get = async (args) => {
  const [emailAddress, clientNo] = args
  const url = `${HOST_URL}/api/octopus/get-expense-allowance/clients/${clientNo}/emailAddress/${emailAddress}`
  const options = {
    headers: CONTENT_TYPE_JSON,
  }
  return sendRequest(url, options)
}
